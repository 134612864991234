// import Form from "../../components/Form";
import styles from "./HomePage.module.scss";
import Product4 from "../../Assets/images/xpboostProdImg.png";
import Product41 from "../../Assets/images/differin_trio 1.png";
import DIFFERIN_V111 from "../../Assets/images/DIFFERIN_V1111.png";
import DESKTOP_BANNER from "../../Assets/images/DESKTOP_BANNER.png";
import MOBILE_BANNER from "../../Assets/images/MOBILE_BANNER.png";
import dropdown from "../../Assets/images/downarrow.gif";
import uparrow from '../../Assets/images/uparrow.gif';
import AcneFomingCleanser from "../../Assets/images/acneCleanser.png";
import AcnePacth from "../../Assets/images/AcnePatch.png";
import AcneSpot from "../../Assets/images/Acnespot.png";
import CESLOGO from "../../Assets/images/cesLogo.png";
import twitchcon1 from "../../Assets/images/twitchcon-1.png";
import twitchcon2 from "../../Assets/images/twitchcon-2.png";
import twitchcon3 from "../../Assets/images/twitchcon-3.png";
import twitchcon4 from "../../Assets/images/twitchcon-4.gif";
import twitchcon5 from "../../Assets/images/twitchcon-5.png";
import twitchcon6 from "../../Assets/images/twitchcon-6.png";
import twitchcon7 from "../../Assets/images/twitchcon-7.png";
import twitchcon8 from "../../Assets/images/twitchcon-8.png";
import twitchcon9 from "../../Assets/images/twitchcon-9.gif";
import twitchcon10 from "../../Assets/images/twitchcon-10.png";
import twitchconDiffLogo from "../../Assets/images/Logo Lockup.png";
const desktopVideo = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/desktop-firstsection-v2.mp4";
const MobileVideo  = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/mobile-firstsection-v2.mp4";
const s3video = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/seehowcanUpdatevideo.mp4"
const HomePage = () => {

  return (
    <>
      <main>
        <section className="Videobanner-desktop section-1 d-none d-lg-block h-100">
          <div className="position-relative bg-7e02a9">
            <video
              autoPlay
              playsInline
              muted
              loop
              poster={DESKTOP_BANNER}
              className="w-100 h-100 mt-minus"
            >
              {desktopVideo && (
                <source src={desktopVideo} />
              )}

            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img className="dropdown-icon" src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-lg-5 col-sm-12">
                  <div className="pe-3 position-absolute ps-lg-0 ps-xl-5 title top-50 translate-middle-y w-50 pb-lg-4 pb-xl-5">
                    <h1>DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p className="max-w-450px">
                    Unlock clearer skin with Differin’s acne-fighting lineup.
                    </p>
                    <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://www.differin.com/Products"
                  >
                    <span>Learn More</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Videobanner-mobile section-1 d-block d-lg-none">
          <div className="position-relative  h-100">
            <video
              autoPlay
              muted
              playsInline
              loop
              poster={MOBILE_BANNER}
              className="w-100 h-100 mw-100"
            >
              {MobileVideo && <source src={MobileVideo} />}

            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img className="dropdown-icon" src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container ">
              <div className="row">
                <div className="col-12">
                  <div className="position-absolute px-3 py-5 title top-0 py-md-5 my-md-5">
                    <h1 className="pt-4">DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p>
                    Unlock clearer skin with Differin’s acne-fighting lineup.
                    </p>
                    <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://www.differin.com/Products"
                  >
                    <span>Learn More</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Ces Section start  here */}
        <section className="videosection cesSection" id="section-2">
            <div className="container">
                <div className="row flex-column flex-lg-row-reverse justify-content-end align-items-end h-100">
                  <div className="col-12 col-lg-5 px-lg-5 text-center text-lg-start">
                      <img className="ceslogo img-fluid" src={CESLOGO} alt="cesLogoimage"></img>
                    </div>
                    <div className="col-12 col-lg-7">
                      <div className="title px-lg-0 px-sm-3 px-xl-5">
                          <h2>Clear Skin, Epic Wins AT CES 2025</h2>
                          <p>
                            Find out how Differin is taking acne care to the next
                            level on the global stage of innovation,
                            CES in the official Content Creator Hub.
                            Join us January 9th at 11:15am in the session
                            <strong> Level Up: Building Confidence and Community for Gamers.</strong> 
                          </p>
                          <a
                          className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                          target="_blank"
                          href="https://www.ces.tech/schedule/level-up-building-confidence-and-community-for-gamers/"
                        >
                      <span>Learn More</span>
                    </a>

                      </div>
                    </div>

                </div>
            </div>
        </section>

        {/* FEATURED LEVEL UP LINEUP Section Starts  here */}
        <section className="levelup-bg section-3">

          <div className="container py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 ps-lg-0 ps-xl-5">
            <div className="align-items-center lineup row m-0" >
              <div className="col-12">
                <div className="title">
                  <h2 className="fullTitle">FEATURED LEVEL UP LINEUP</h2>
                  <p>
                  It’s time to level up your skin! The Level Up featured lineup equips you
                  with Differin’s acne-fighting arsenal. Take control,
                  clear the map of breakouts, and achieve the high score in glowing skin. 
                  </p>
                </div>
              </div>

              <div className="col-12 col-lg-4 pt-5">
                <div className="prodimageWrap">
                    <img className="img-fluid" src={AcneFomingCleanser} />
                </div>
                <div className="Prodinfo">
                  <div className="content">
                    <h3>Acne Foaming Cleanser</h3>
                    <p>Maximum Strength Acne Foaming Cleanser with 10% Benzoyl Peroxide has the strongest concentration available without a prescription.</p>

                  </div>
                  <a
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                      target="_blank"
                      href="https://www.differin.com/shop/Maximum-Strength-Acne-Foaming-Cleanser/302994137008.html"
                    >
                      <span>Learn More</span>
                    </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 pt-5">
                <div className="prodimageWrap">
                    <img className="img-fluid" src={AcnePacth} />
                </div>
                <div className="Prodinfo">
                  <div className="content">
                    <h3>Acne-Prone Skin Patches</h3>
                    <p>Differin Power Patches are formulated with salicylic acid to exfoliate to help shrink the appearance of emerging pimples day and night, keeping your game going.  </p>
                  </div>
                  <a
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                      target="_blank"
                      href="https://www.differin.com/shop/salicylic-acid-acne-prone-patches/4260328611456.html"
                    >
                      <span>Learn More</span>
                    </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 pt-5">
                <div className="prodimageWrap">
                    <img className="img-fluid" src={AcneSpot} />
                </div>
                <div className="Prodinfo">
                  <div className="content">
                    <h3>Acne Spot Treatment</h3>
                    <p>Contains 10% Benzoyl Peroxide, the maximum strength available over-the counter, to clear blemishes, reduce acne size and redness.</p>
                  </div>
                  <a
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                      target="_blank"
                      href="https://www.differin.com/shop/10%25Benzoyl-Peroxide-Spot-Treatment/302994611003.html"
                    >

                      <span>Learn More</span>
                    </a>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* seehow Section starts  here */}
        <section className="videosection seehowUpdatedImage section-2" id="section-2">
          <div className="container">
            <div className="row">
                <h2 className="mx-auto text-uppercase text-center text-uppercase text-white fw-bolder max-w-700px mb-xl-5 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                  See HOW You CAN POWER UP YOUR acne game.
                </h2>
            </div>
              <video
                id="my-video-1"
                className="video-js vjs-default-skin mx-auto"
                controls
                loop
                preload="none"
                poster={DIFFERIN_V111} controlsList="nodownload"
                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'
              >
                {s3video && (
                  <source src={s3video} type="video/mp4" />
                )}
              </video>



          </div>
        </section>

        {/* XP Boost starts  here */}
        <section className="levelup-bg xpBoost section-5 position-relative pb-xl-0 pb-lg-0 pb-md-5 pb-sm-5 pb-5">
          {/* <img src={levelup} alt="levelupbg" /> */}

          <div className="container position-relative px-0">
            <div className="position-absolute end-0 img-container-products d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none">
              <div className="xpboostDeskImg">
                  <div className="position-absolute downarrow">
                    <img src={uparrow} alt="uparrow" className="uparrow" />
                  </div>
                  <img
                    className="img-fluid"
                    src={Product4}
                    alt="Product"
                  />
                </div>
            </div>
          <div className="row d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block pt-5 mx-0">
              <div className="col-12 d-flex justify-content-end px-0">
                <div className="position-relative max-w-730px">
                  <div className="position-absolute downarrow">
                    <img src={uparrow} alt="uparrow" className="d-block uparrow" />

                  </div>
                  <div className="imgwrapper">
                    <img
                      className="img-fluid"
                      src={Product41}
                      alt="Product"
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className="row levelup-text-sec">
              <div className="col-12 px-0">
                <div className="title px-xl-5 px-lg-0 px-md-5 px-sm-4 px-3 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-1 mx-1 my-5">
                  <h2>XP BOOST ACTIVATED. FIND DIFFERIN ONLINE. </h2>
                  <p className="max-w-386px">
                    Tired of battling stubborn breakouts? It’s time to elevate your skincare routine. Differin is the secret weapon that has clinically proven ingredients for battling those nasty pimples. It’s like a cheat code for acne. Make every day a win with Differin.
                  </p>
                  <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://www.amazon.com/stores/Differin/page/7EAC0F7D-69DD-43B9-ABD2-1AC0783F7FDC?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto"
                  >
                    <span>Buy Differin Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* twitchcon starts  here */}
        <section className="twitch-con section-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="logowrap" >
                  <img  src={twitchconDiffLogo} alt="twitchconand Differinlogo"/>
                </div>
                <div className="title px-lg-2 px-md-5 px-sm-2 my-2">
                  <h2 className="fullTitle">LEVELING UP TOGETHER</h2>
                  <p className="">
                    Differin is all about connecting gamers, building communities, and streaming with confidence. <br/>
                    Conquer breakouts with Differin so you can focus on what really matters: making connections and epic wins.
                  </p>
                  <p className="d-block d-md-none d-lg-none d-xl-none d-xxl-none twichconlastp">Meet our featured Twitch Streamers and see how they level up their game. </p>
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-12 col-lg-12">
                <img className="img-fluid" src={twitchcon1} alt="img1" />
              </div>
              <div className="col-12 col-lg-6">
                <img className="img-fluid" src={twitchcon2} alt="img2" />
              </div>
              <div className="col-12 col-lg-6">
                <div className="row g-2">
                  <div className="col-12"><img className="img-fluid" src={twitchcon3} alt="img2" /></div>
                  <div className="col-12"><img className="img-fluid" src={twitchcon4} alt="img2" /></div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-2">
                  <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon5} alt="img5" /></div>
                  <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon6} alt="img6" /></div>
                  <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon7} alt="img7" /></div>
                  <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon8} alt="img8" /></div>
                </div>
              </div>
              <div className="col-12 col-lg-6"><img className="img-fluid" src={twitchcon9} alt="img2" /></div>
              <div className="col-12 col-lg-6"><img className="img-fluid" src={twitchcon10} alt="img2" /></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;
